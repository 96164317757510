@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  #__next {
    @apply h-full flex flex-col;
  }

  html,
  body {
    @apply h-full;
    @apply dark:bg-gray-800 bg-white
  }
}

input[type="range"]::-webkit-slider-thumb {
  pointer-events: all;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
  /* @apply w-6 h-6 appearance-none pointer-events-auto; */
}

:root {
  --app-bar-margin: 2.12rem;
}

.xh1 {
  color: var(--gray-950, #020618);
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 3.9rem */
}

.xh2 {
  color: var(--gray-950, #020618);
  font-size: 1.3125rem;
  font-weight: 600;
  line-height: 120%;
}

.xh3 {
  color: var(--gray-950, #020618);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.xh4 {
  color: var(--gray-950, #020618);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: rgb(248 250 253 / var(--tw-bg-opacity));
}

.scrollbar::-webkit-scrollbar-track:hover {
  background-color: rgb(248 250 253 / var(--tw-bg-opacity));
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 8px;
  border: 4px solid rgb(248 250 253 / var(--tw-bg-opacity));
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}